import ResyncConcreteLoadByBcqdOrderId from 'src/busines-pages/orders/components/ResyncConcreteLoadByBcqdOrderId';
import ResyncConcreteLoadById from 'src/busines-pages/orders/components/ResyncConcreteLoadById';
import ResyncConcreteLoadByProjectId from 'src/busines-pages/orders/components/ResyncConcreteLoadByProjectId';
import TopBar from '../TopBar';
import GetSubscriptions from './GetSubscriptions';
import CreateSubscription from './CreateSubscription';
import GetDeliveryNotes from './GetDeliveryNotes';

const DsaPage = (): JSX.Element => {
  return (
    <TopBar>
      <CreateSubscription />
      <GetSubscriptions />
      <GetDeliveryNotes />
      <ResyncConcreteLoadById />
      <ResyncConcreteLoadByBcqdOrderId />
      <ResyncConcreteLoadByProjectId />
    </TopBar>
  );
};

export default DsaPage;
